import React, { useEffect } from "react";
import CourseHeader from "../../header/index";

import Footer from "../../footer";
import { Link, useParams } from "react-router-dom";
import user7 from '../../../assets/img/user/836.jpg'
import StudentSideBar from "../../student/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { EditorialsgetById } from "../../../Redux/Editorial/editorialSlice";
import Bannerpage from "../../Banner";

const Editorinchief = () => {

  const { editorial } = useSelector((state) => state.editorialData)

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(EditorialsgetById(slug));
  }, [])

  return (
    <>
      <div className="main-wrapper">
        <CourseHeader activeMenu={"CourseDetails"} />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        The Complete Web Developer Course 2.0
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Bannerpage />

        <section className="page-content course-sec">
          <div className="container">
            <div className="row">
              <StudentSideBar />
              <div className="col-lg-8">
                {editorial?.data?.[0]?.editor_in_chiefs?.map((item, index) => {
                  return <div className="card overview-sec" key={index}>
                    <div className="card-body">
                      <h5 className="subs-title">Editor-in-Chief</h5>
                      {/* <h6>Course Description</h6> */}
                      <div className="row">
                        <div className="col-lg-3 col-md-6 d-flex">
                          <div className="instructor-box flex-fill">
                            <div className="instructor-img">
                              <Link>
                                <img className="img-fluid" alt="" src={item?.image_url || user7} crossOrigin="anonymous" />
                              </Link>
                            </div>

                          </div>
                        </div>
                        <div className="col-lg-8 col-md-6 d-flex">
                          <div className="instructor-content">
                            <h5>
                              <Link to={`/${slug}/instructor-profile/${window.btoa(item?.uuid)}/cheif`} style={{ textAlign: "center" }}>{item?.name}</Link>
                            </h5>
                            <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.about }}></div>
                          </div>
                        </div>
                      </div>
                      <div className="instructor-content">
                        <h5>
                          <Link style={{ textAlign: "center" }}>Biography</Link>
                        </h5>
                        <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.biography }}></div>
                      </div>
                      <div className="instructor-content">
                        <h5>
                          <Link style={{ textAlign: "center" }}>Research Interest</Link>
                        </h5>
                        <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.research_interest }}></div>
                      </div>
                    </div>
                  </div>
                })}

              </div>
            </div>
          </div>
        </section>
        <Footer />

      </div>
    </>
  );
};

export default Editorinchief;
