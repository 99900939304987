import React, { useEffect } from 'react'
import Footer from '../footer'
import Header from '../header'
import { useParams } from 'react-router-dom'
import { JournalList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";

const Policies = () => {

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
    }, [name])

    // const RemoveTags = (string) => {
    //   const withoutTags = string.replace(/<\/?[^>]+(>|$)/g, "");
    //   return withoutTags;
    // }

    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">{journalList?.data?.page_heading}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace'>
                <div className='container'>
                    <div className='card'>
                        <div className='card-body'>
                            <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div>
                            {/* <ul>
                                <li> Competing interests</li>
                                <li>Human and animal rights</li>
                                <li>Informed consent</li>
                            </ul>
                            <h4>Competing interests</h4>
                            <p>
                                Publishing requires authors to declare all competing interests in relation to their work.
                                All submitted manuscripts must include a ‘competing interests’ section at the end of the
                                manuscript listing all competing interests (financial and non-financial). Where authors
                                have no competing interests, the statement should read “The authors have declared that no
                                competing interests exist.” Editors may ask for further information relating to competing
                                interests. Editors and reviewers are also required to declare any competing interests and
                                will be excluded from the peer review process if a competing interest exists.
                            </p>
                            <p>
                                Competing interests may be financial or non-financial. A competing interest
                                exists when the authors’ interpretation of data or presentation of information
                                may be influenced by their personal or financial relationship with other people
                                or organizations. Authors should disclose any financial competing interests but
                                also any non-financial competing interests that may cause them embarrassment if
                                they were to become public after the publication of the article.
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Policies