import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// const [data, setData] = useState('')
const initialState = {
    isLogin: false,
    archive: {},
    toastData: {},
};

const homeSlice = createSlice({
    name: 'archive',
    initialState,
    reducers: {
        setArchiveById(state, action) {
            state.archive = action.payload;
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
    },
});

export const { settoastData, setArchiveById } = homeSlice.actions;

export const ArchivegetById = (id) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/journalArchieve/${id}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setArchiveById(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}
const archiveReducer = homeSlice.reducer;

export default archiveReducer;
