import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { User8 } from "./../../imagepath";
// import { useSelector } from "react-redux";
// import { Minus, Plus } from "react-feather";

// import { ProfileBg, User11 } from "../../imagepath";

// eslint-disable-next-line react/prop-types
export default function StudentSideBar({ activeMenu }) {

  const [collapse, setCollapse] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);

  const toggleCollapse = () => setCollapse(!collapse);
  const toggleCollapse2 = () => setCollapse2(!collapse2);
  const toggleCollapse3 = () => setCollapse3(!collapse3);

  const { slug } = useParams();

  return (
    <div className="col-xl-3 col-md-3 col-lg-3 theiaStickySidebar">
      <div className="settings-widget account-settings">
        <div className="settings-menu">
          <h3> Menu</h3>
          <ul>
            <li
              className={
                activeMenu === "EditProfile" ? "nav-item active" : "nav-item"
              }
            >
              <Link to={`/${slug}/journal-page`} className="nav-link">
                Journal Home
              </Link>
            </li>
            <div className="faq-card" >
              <li
                className="faq-title faqFlex"
                onClick={toggleCollapse}
              >
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqone"
                  aria-expanded="true"
                  style={{ color: "#000" }}
                >
                  Editorial Panel
                </Link>
                {collapse ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}
              </li>
              <div id="faqone" className={collapse ? "collapse show" : "collapse"}>
                <div className="faq-detail">
                  <li className="nav-item">
                    <Link
                      to={`/${slug}/editor-in-chief`}
                      className={
                        activeMenu === "SocialProfile"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <i>
                        <i className="fas fa-chevron-right" />
                      </i>{" "}
                      Editor-in-chief
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={`/${slug}/editor-board`}
                      className={
                        activeMenu === "Notification" ? "nav-item active" : "nav-item"
                      }
                    >
                      <i>
                        <i className="fas fa-chevron-right" style={{ width: '50%' }} />
                      </i>
                      Editorial Board
                    </Link>
                  </li>
                </div>
              </div>
            </div>
            <div className="faq-card" onClick={toggleCollapse2}>
              <li className="faq-title faqFlex">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqtwo"
                  style={{ color: "#000" }}
                >
                  Instructions for Authors
                </Link>
                {collapse ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}
              </li>
              <div id="faqtwo" className={collapse2 ? "collapse show" : "collapse"}>
                <div className="faq-detail">
                  <li className="nav-item">
                    <Link
                      to={`/${slug}/instructios-for-authors`}
                      className={
                        activeMenu === "DeleteProfile"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <i>
                        {" "}
                        <i className="fas fa-chevron-right" />

                      </i>{" "}
                      Instructions for Authors
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={`/${slug}/publication-malpractice-statement`}
                      className={
                        activeMenu === "Accounts" ? "nav-item active" : "nav-item"
                      }
                    >
                      <i>
                        <i className="fas fa-chevron-right" />
                      </i>{" "}
                      Publication ethics & malpractice statement
                    </Link>
                  </li>
                </div>
              </div>
            </div>

            <li className="nav-item">
              <Link
                to={`/${slug}/journal-submitmanuscript`}
                className={
                  activeMenu === "Referral" ? "nav-item active" : "nav-item"
                }
              >
                Submit Manuscript
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/${slug}/journal-aim-scope`}
                className={
                  activeMenu === "Referral" ? "nav-item active" : "nav-item"
                }
              >
                Aim and Scope
              </Link>
            </li>

            <li className="nav-item">
              <Link to={`/${slug}/journal-articles`} className="nav-link">
                Articles in process
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/${slug}/journal-archive`}
                className={
                  activeMenu === "Referral" ? "nav-item active" : "nav-item"
                }
              >
                Archive
              </Link>
            </li>
            <div className="faq-card" onClick={toggleCollapse3}>
              <li className="faq-title faqFlex">
                <Link
                  className="collalised"
                  data-bs-toggle="collapse"
                  to="#faqthree"
                  style={{ color: "#000" }}
                >
                  Special Issues
                </Link>
                {collapse ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}
              </li>

              <div id="faqthree" className={collapse3 ? "collapse show" : "collapse"}>
                <div className="faq-detail">
                  <li className="nav-item">
                    <Link
                      to={`/${slug}/journal-guidelines`}
                      className={
                        activeMenu === "Referral" ? "nav-item active" : "nav-item"
                      }
                    >
                      <i className="fas fa-chevron-right" />
                      Guidelines
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={`/${slug}/journal-upcoming`} className="nav-link">
                      <i>
                        <i className="fas fa-chevron-right" />
                      </i>{" "}
                      Upcoming Special Issues
                    </Link>
                  </li>
                </div>
              </div>
            </div>
            <li className="nav-item">
              <Link
                to={`/${slug}/journal-contact`}
                className={
                  activeMenu === "Referral" ? "nav-item active" : "nav-item"
                }
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="sidebar-sec">
          <div className="card include-sec">
            <div className="card-body">
              <div className="cat-title">
                <h4>ADS HERE</h4>
              </div>
              <div className="pb-3">
                <img src={User8} width={200} />
              </div>
            </div>
          </div>

          <div className="card feature-sec">
          </div>
        </div>
      </div>
    </div>
  );
}
