import React, { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import OwlCarousel from "react-owl-carousel";

import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../header";
import { MoreClassifications } from "../../Redux/home/homeslice";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col } from "react-bootstrap";

const MoreCalssifications = () => {

  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { moreClassifications } = useSelector((state) => state.Home);

  useEffect(() => {
    dispatch(MoreClassifications(slug));
  }, [])

  const NavigateFun = (dataID, jounalsD) => {
    navigation(`/${dataID?.journal_slug}/journal-page`, {
      state: { journals: jounalsD } // 'state' is used to pass the data
    });
  }

  return (
    <div className="main-wrapper">
      <Header />
      <section
        className="section trend-course"
        style={{ marginTop: '5%' }}
      >
        <div className="container">
          <div className="section-header aos" >
            <div className="section-sub-head">
              <h2>
                {slug}
              </h2>
            </div>
          </div>
          <div className="section-text aos" >
            <div className="mb-5" dangerouslySetInnerHTML={{ __html: moreClassifications?.data?.[0]?.description }}></div>
          </div>
          <div className="row g-4">
            {moreClassifications?.data?.[0]?.journals?.map((item, index) => (
              <div className="col-md-3 col-lg-3 col-xl-3" key={index}>
                <Card className="h-100 journal-card">
                  <Row className="g-0">
                    <Col xs={12} className="journal-img-wrapper">
                      <img
                        src={item?.image_url}
                        alt={item?.journal_name}
                        className="w-100 journal-img"
                        crossOrigin="anonymous"
                      />
                    </Col>
                    <Col xs={12} className="p-3">
                      <div className="journal-title">
                        <b onClick={() => NavigateFun(item, moreClassifications?.data?.[0]?.journals)}>
                          <Link className="journal-link">{item?.journal_name}</Link>
                        </b>
                        <p className="text-muted mb-0">
                          Volume {item?.volume}, Issue {item?.issue}
                        </p>
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='all-btn all-category'>
                            <Link
                              to={`/${item?.journal_slug}/journal-archive`}
                              className="btn btn-primary"
                            >
                              Archive
                            </Link>
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='all-btn all-category'>
                            <Link
                             to={`/${item?.journal_slug}/manuscript-form`}
                              className="btn btn-primary"
                            >
                              Submit Paper
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="journal-stats mt-3">
                        <div className="stat-item">
                          <p className="text-center mb-2">
                            Average acceptance to publication time
                          </p>
                          <p className="text-center fw-bold">
                            {item?.publication_time}
                          </p>
                        </div>
                        <div className="stat-item mt-2">
                          <p className="text-center mb-2">
                            Average article processing time
                          </p>
                          <p className="text-center fw-bold">
                            {item?.processing_time}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MoreCalssifications;
