import React, { useEffect } from 'react'
import Footer from '../footer'
import Header from '../header'
import { useParams } from 'react-router-dom'
import { JournalList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";

const Confrences = () => {

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
    }, [name])


    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">{journalList?.data?.page_heading}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace'>
                <div className='container'>
                    <h1>Guide for reviewers</h1>
                    <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div>
                    {/* <p>Conferences can avail the opportunity of publishing the conference abstracts,
                        proceedings, and full length papers in our journals. A well structured editorial
                        committee comprising reputed authors and academicians carefully scrutinize the same
                        and offer their suggestions for improvisations, before getting them published finally.
                    </p>
                    <p>Submissions of full length articles along with conference abstracts can be made to
                        For more information on conferences please here</p> */}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Confrences