import React from "react";
import {
    useSelector
} from "react-redux";
import { Link } from "react-router-dom";
const Bannerpage = () => {

    const { homeJournalDataById } = useSelector((state) => state.Home)

    return (
        <>
            <div
                className="inner-banner"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="instructor-wrap border-bottom-0 m-0">
                                <div className="about-instructor align-items-center">
                                </div>
                            </div>
                            <h2>{homeJournalDataById?.data?.[0]?.journal_name}</h2>
                            <p>
                                Learn Web Development by building 25 websites and mobile apps
                                using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
                            </p>

                            <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                                style={{ justifyContent: "end" }}
                            >
                                <div className="cou-info">
                                    <h4 style={{ textAlign: "end" }}>{homeJournalDataById?.data?.[0]?.is_open_access ? "Open Access" : ''}</h4>
                                </div>
                            </div>
                            <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                                style={{ justifyContent: "start" }}
                            >
                                <div className="cou-info">
                                    <div className='all-btn all-category'>
                                        <Link
                                            to="/online-submission"
                                            className="btn btn-primary"
                                        >
                                            Submit Manuscript
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <p>ISSN: {homeJournalDataById?.data?.[0]?.issn}</p>
                            <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp" ></i> {homeJournalDataById?.data?.[0]?.whatsapp_number}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bannerpage;