import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLogin: false,
    homeData: {},
    homeJournalData: {},
    homeJournalDataById: {},
    toastData: {},
    moreClassifications: {},
    settings: {},
    highLightIssues: {}
};

const homeSlice = createSlice({
    name: 'Home',
    initialState,
    reducers: {
        setHomeData(state, action) {
            state.homeData = action.payload;
        },
        setHomeJournalData(state, action) {
            state.homeJournalData = action.payload;
        },
        setHomeJournalDataById(state, action) {
            state.homeJournalDataById = action.payload;
        },
        setMoreClassifications(state, action) {
            state.moreClassifications = action.payload
        },
        setSettings(state, action) {
            state.settings = action.payload
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
        setHighLightIssues(state, action) {
            state.highLightIssues = action.payload
        }
    },
});

export const { setHomeData, settoastData, setHomeJournalData, setHomeJournalDataById, setMoreClassifications, setSettings, setHighLightIssues } = homeSlice.actions;

export const HomeDataget = () => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/home`).then((res) => {
        dispatch(setHomeData(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const getCurrentHighlights = (journal_uuid) => async (dispatch) => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/currentIssueHighlightHome?journal_uuid=${journal_uuid}`).then((res) => {
        dispatch(setHighLightIssues(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const HomeJournalDataget = (pageNumber, limit) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/classficationList?page=${pageNumber}&limit=${limit}`).then((res) => {
        dispatch(setHomeJournalData(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const MoreClassifications = (slugName) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/classification/details/${slugName}`).then((res) => {
        dispatch(setMoreClassifications(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const HomeJournalDatagetById = (id) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/${id}`).then((res) => {
        dispatch(setHomeJournalDataById(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const settingsData = () => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/websetting`).then((res) => {
        dispatch(setSettings(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}
const homeReducer = homeSlice.reducer;

export default homeReducer;
