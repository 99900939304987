import React from "react";
import Header from '../header/index'
import '../Journal/journal.css'
import { Link } from "react-router-dom";
import { logo } from "../imagepath";
// import {
//   User,
// } from ".././imagepath";
import user7 from '../../assets/img/user/836.jpg'
import { Card, CardBody, Col, Row } from "react-bootstrap";
import InstructorSidebar from "../instructor/sidebar/index";
import { communications } from "../home/slider/topics";
import Footer from "../footer";
import Header5 from "../header 5";

const home = () => {


  return (
    <>
      <Header />
      <div className="top-head ">
        <div className="container " >
          <Row>
            <Col>
              <Link to="/" className="menu-logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </Col>
            <Col>
                <h2 className="mb-0" style={{ color: "#fff" }}>
                Journal-1
                </h2>
                <p style={{ color: "#fff", textAlign:"end" }}>Open Access</p>
            </Col>
            <Col>
              <h4 className=" text-center pb-2" style={{ color: "#fff" }}>ISSN: 2165-7548</h4>
              <div className="go-dashboard text-center pb-2">
                <p className="btn btn-primary ">
                  <i className="fa-brands fa-whatsapp" /> &nbsp;
                  +44 1223 790975
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
     <Header5 />

      <div className="container pb-2 pt-2" >
        <Row>
        
          <Col style={{backgroundColor:"#fff"}}>
            <Card className="cardb">
              <CardBody >
                <ul>
                  <li>Journal h-index : 9</li>
                  <li>Journal cite score : 2.29</li>
                  <li>Journal impact factor : 1.20</li>
                  <li>Average acceptance to publication time (5-7 days)</li>
                  <li>Average article processing time (30-45 days)</li>
                </ul>
              </CardBody>
            </Card>

            <div className="row">
              <h1>Editorial Board</h1>
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="instructor-box flex-fill">
                  <div className="instructor-img">
                    <Link to="/instructor-profile">
                      <img className="img-fluid" alt="" src={user7} />
                    </Link>
                  </div>
                  <div className="instructor-content">
                    <h5>
                      <Link to="/instructor-profile">Rolands R</Link>
                    </h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Integer volutpat dolor ornare est cursus facilisis.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="instructor-box flex-fill">
                  <div className="instructor-img">
                    <Link to="/instructor-profile">
                      <img className="img-fluid" alt="" src={user7} />
                    </Link>
                  </div>
                  <div className="instructor-content">
                    <h5>
                      <Link to="/instructor-profile">Rolands R</Link>
                    </h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Integer volutpat dolor ornare est cursus facilisis.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="instructor-box flex-fill">
                  <div className="instructor-img">
                    <Link to="/instructor-profile">
                      <img className="img-fluid" alt="" src={user7} />
                    </Link>
                  </div>
                  <div className="instructor-content">
                    <h5>
                      <Link to="/instructor-profile">Rolands R</Link>
                    </h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Integer volutpat dolor ornare est cursus facilisis.</p>
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col lg={9} md={9} >
                <Card className="card">
                  <CardBody >
                    <h5>Submit Manuscript</h5>
                    <p>Submit manuscript at Online Submission System or send as an e-mail
                      attachment to the Editorial Office</p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3} md={3} >
                <Card className="card">
                  <CardBody >
                    <strong>Table of Contents</strong>
                    <p>Volume 13, 2024</p>
                    <p>Archive</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div>
              <h1>About the Journal</h1>
              <strong>Index Copernicus Value 2015: 62.2</strong>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis erat augue, aliquet vitae
                mattis in, lobortis vitae libero. Cras libero tellus, semper eget tristique ut, dignissim
                nec nisl. Aenean facilisis massa a felis mattis euismod. Nunc nec nisi at quam tristique
                varius. Vivamus at magna est. Pellentesque vitae ipsum a enim placerat fringilla vitae a
                ligula. Nullam vitae magna venenatis, aliquet purus id, iaculis lorem. Mauris in vehicula
                risus, nec luctus sapien. Nunc ullamcorper nibh non lorem efficitur facilisis. Fusce lacinia
                velit est, vel iaculis sem porta in. Nam quis lorem lobortis, vulputate sem malesuada,
                dignissim risus.</p>
              <p>Mauris vel placerat urna. Nullam ac dapibus sapien. Nunc dapibus luctus ex.
                Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                Nunc lacus augue, bibendum id odio sit amet, aliquet volutpat augue. Nunc ut libero ut
                magna iaculis porta. Maecenas condimentum quam at neque eleifend auctor. Nulla facilisi.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
                Donec eu convallis risus. Donec in tempor libero.</p>
            </div>
            <div>
              <Card className="card">
                <CardBody >
                  <h5>h-index</h5>
                  <p>Articles published in Andrology-Open Access have been cited by esteemed scholars and
                    scientists all around the world. Andrology-Open Access has got h-index 9 , which means
                    every article in Andrology-Open Access has got 9 average citations.</p>
                </CardBody>
              </Card>
            </div>
            <div>
              <h1>Journal Highlights</h1>
              <Row className="cardb">
                <Col>
                  <ul>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 1</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 2</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 3</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 4</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 5</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 6</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 7</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 8</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 9</li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div>
              <h1>Current Issue Highlights</h1>
              <div className="row">
                {communications.map((detail, index) => (
                  <div className="col-lg-4 col-md-6 d-flex" key={index}>
                    <div className="course-box trend-box">
                      <div className="product trend-product">
                        <div className="product-content">
                          <div>
                            <p>{detail.head}</p>
                          </div>
                          <p className="title mb-2">
                            <Link to="course-details">
                              {detail.name}
                            </Link>
                          </p>
                          <div className="course-group d-flex">
                            <div className="course-group-img d-flex">
                              <Link to="/instructor-profile">
                                <img src={user7} alt="" className="img-fluid" />
                              </Link>
                              <div className="course-name">
                                <h4>
                                  <Link to="/instructor-profile">John Michael</Link>
                                </h4>
                                <p>Instructor</p>
                              </div>
                            </div>
                          </div>
                          <div className="course-info d-flex align-items-center">
                          </div>
                          <div className="all-category d-flex align-items-center">
                            <Link to="/instructor-list" className="me-4">
                              Abstract
                            </Link>
                            <Link to="/instructor-list" className="me-4">
                              HTML
                            </Link>
                            <Link to="/instructor-list" className="mr-4">
                              PDF
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h1>Relevant Topics</h1>
              <Row className="cardb">
                <Col>
                  <ul>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 1</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 2</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 3</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 4</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 5</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 6</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 7</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 8</li>
                    <li><i className="fas fa-chevron-right" /> &nbsp; Journal 9</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
          <InstructorSidebar activeMenu="Courses" />
        </Row>
      </div>
      <Footer />
    </>
  )
}

export default home