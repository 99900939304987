import React, { useEffect, useState } from "react";
import CourseHeader from "../header";
import '../Journal/journal.css'
import Footer from "../footer";
import StudentSideBar from "../student/sidebar";
import { Link, useParams } from "react-router-dom";
import Bannerpage from "../Banner";
import { useDispatch, useSelector } from "react-redux";
import { ContactInfoById } from "../../Redux/Contact/constactslice";
import axios from "axios";
import { settingsData } from "../../Redux/home/homeslice";

const JournalContact = () => {

  const { contactinfo } = useSelector((state) => state.contactData)

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    journalclassification_uuid: contactinfo?.data?.classification_uuid,
    message: ""
  });

  const handleSubmitAPI = (e) => {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_URL}/contactQuery`, formData)
      .then((res) => {
        console.log(res?.data)
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          journalclassification_uuid: "",
          message: ""
        })
      })
      .catch((err) => {
        console.log(err?.response?.data)
      })
    console.log(formData);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(ContactInfoById(slug));
    dispatch(settingsData());
  }, [])

  const { settings } = useSelector((state) => state.Home);

  console.log(settings, "sdsds")

  return (
    <>
      <CourseHeader activeMenu={"CourseDetails"} />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      All Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      The Complete Web Developer Course 2.0
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bannerpage />

      <div className="page-content">

        <div className="container">
          <h3>
            <center>
              Contact Us
            </center>
          </h3>
          <div className="row">
            <StudentSideBar />
            <div className="col-lg-8 col-md-8 mx-auto">
              <div className="support-wrap">
                <div className="row">
                  <div className="col-md-5 col-lg-5 col-xl-5 col-sm-6">
                    <strong>Address</strong>
                    <br />
                    <label dangerouslySetInnerHTML={{ __html: contactinfo?.data?.address }}>
                    </label>
                    <br />

                    <strong>Registered Address</strong>
                    <br />
                    <label
                      dangerouslySetInnerHTML={{ __html: contactinfo?.data?.registered_address }}
                    >
                    </label>
                    <br />

                    <strong>Phone Number</strong>
                    <br />
                    <label>
                      {contactinfo?.data?.phone_number}
                    </label>
                    <br />

                    <strong>Email</strong>
                    <br />
                    <label>
                      {contactinfo?.data?.email}
                    </label>
                    <br />

                    <strong>Reprints / Advertisement</strong>
                    <br />
                    <label>
                      {contactinfo?.data?.reprint_advertisement}
                    </label>

                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6">
                    <h5> Journal Name : Lorem Ipsum</h5>
                    <form onSubmit={handleSubmitAPI} >
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              name="first_name"
                              value={formData.first_name}
                              onChange={handleChange}
                              type="text"
                              className="form-control"
                              placeholder="Enter your first name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleChange}
                              type="text"
                              className="form-control"
                              placeholder="Enter your last name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="email"
                              className="form-control"
                              placeholder="Enter your email address"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Phone</label>
                            <input
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange}
                              type="tel"
                              className="form-control"
                              placeholder="Enter your phone number"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Message</label>
                            <textarea
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="Write down here"
                              rows={4}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <button className="btn-submit">Submit</button>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default JournalContact