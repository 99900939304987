import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// const [data, setData] = useState('')
const initialState = {
    isLogin: false,
    aimScope: {},
    toastData: {},
};

const homeSlice = createSlice({
    name: 'aimScope',
    initialState,
    reducers: {
        setAimandScopeById(state, action) {
            state.aimScope = action.payload;
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
    },
});

export const { settoastData, setAimandScopeById } = homeSlice.actions;

export const AimScopegetById = (id) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/aimsAndScope/${id}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setAimandScopeById(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}
const aimScopeReducer = homeSlice.reducer;

export default aimScopeReducer;
