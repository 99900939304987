import React, { useEffect, useState } from 'react';
import Footer from '../footer';
import Header from '../header';
import { useParams } from 'react-router-dom';
import { JournalList, JournalAtoZList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const Journalfilter = () => {
    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList, atoz } = useSelector((state) => state.jounalListData);

    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
        dispatch(JournalAtoZList());
    }, [name]);

    const [selectedLetter, setSelectedLetter] = useState('A');

    const handleLetterClick = (letter) => {
        setSelectedLetter(letter);
        const section = document.getElementById(`journal-${letter}`);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    // Check which letters have associated data
    const lettersWithData = new Set(atoz?.data?.map(item => item._id) || []);

    return (
        <>
            <Header />

            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">Pages</li>
                                        <li className="breadcrumb-item">Support</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">{journalList?.data?.page_heading}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className='topspace container'>
                <div className='card'>
                    <div className='card-body'>
                        <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div>
                    </div>
                </div>
                <Row>
                    <Col md={2} lg={2}>
                        {/* <h6 style={{ padding : '20px', backgroundColor : 'red', borderRadius : 8 ,}}>Journals by Title</h6> */}
                        <h6 style={{
                            padding: '10px',
                            backgroundColor: '#02CCFE',
                            borderRadius: '8px 0 0 8px',
                            color : 'white',
                            clipPath: 'polygon(0 0, calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%)'
                        }}>
                            Journals by Title
                        </h6>

                    </Col>
                    <Col md={10} lg={10}>
                        <div className="alphabet-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {alphabet.map(letter => (
                                <div
                                    key={letter}
                                    className={`alphabet-letter ${!lettersWithData.has(letter) ? 'disabled' : ''}`}
                                    style={{
                                        backgroundColor: selectedLetter === letter ? '#02CCFE' : '',
                                        cursor: lettersWithData.has(letter) ? 'pointer' : 'not-allowed',
                                        color: lettersWithData.has(letter) ? 'inherit' : '#ccc',
                                        padding: '8px',
                                        borderRadius: '4px'
                                    }}
                                    onClick={() => lettersWithData.has(letter) && handleLetterClick(letter)}
                                >
                                    {letter}
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>


                {atoz?.data?.map((item, index) => (
                    <div key={index} id={`journal-${item._id}`} className='journal-section'>
                        <h3>{item?._id}</h3>
                        <div className='row pt-3'>
                            {item?.journals?.map((itemData, indexData) => (
                                <div className='col-3' key={indexData} >
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h6>{itemData?.journal_name}</h6>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='all-btn all-category'>
                                                        <Link
                                                            to={`/${itemData?.journal_slug}/journal-archive`}
                                                            className="btn btn-primary"
                                                        >
                                                            Archive
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='all-btn all-category'>
                                                        <Link
                                                            to={`/${itemData?.journal_slug}/manuscript-form`}
                                                            className="btn btn-primary"
                                                        >
                                                            Submit Paper
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-9'>
                                                    <p>Journal impact factor</p>
                                                </div>
                                                <div className='col-3'>
                                                    <p>{itemData?.impact_factor}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-9'>
                                                    <p>Journal h-index</p>
                                                </div>
                                                <div className='col-3'>
                                                    <p>{itemData?.h_index}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-9'>
                                                    <p>Journal cite Score</p>
                                                </div>
                                                <div className='col-3'>
                                                    <p>{itemData?.city_score}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <p>Average acceptance to publication time ({itemData?.publication_time})</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <p>Average article processing time ({itemData?.processing_time})</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <Footer />
        </>
    )
}

export default Journalfilter;
