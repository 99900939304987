import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLogin: false,
    article: {},
    articleByarticleId: {},
    toastData: {},
};

const homeSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        setArticleById(state, action) {
            state.article = action.payload;
        },
        setArticleByarticleId(state, action) {
            state.articleByarticleId = action.payload;
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
    },
});

export const { settoastData, setArticleById, setArticleByarticleId } = homeSlice.actions;

export const ArticlegetById = (id) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/articleInPress?journal_uuid=${id}`).then((res) => {
        dispatch(setArticleById(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}
export const ArticlegetByArticleId = (id) => async (dispatch) => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/articleListByVolume?volume_issue_uuid=${id}`).then((res) => {
        dispatch(setArticleByarticleId(res.data));
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}
const articleReducer = homeSlice.reducer;

export default articleReducer;
