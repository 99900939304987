import React from "react";
  import { Link } from "react-router-dom";
// import { InstructorProfileBg, User15 } from "../../imagepath";
import StickyBox from "react-sticky-box";
import { User7, User8 } from "../../imagepath";

// eslint-disable-next-line react/prop-types
export default function InstructorSidebar() {
  return (
    <div className="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar" >
      <StickyBox offsetTop={20} offsetBottom={20} style={{backgroundColor:"#fff", padding:"10px"}}>
        <div className="sideStickyBar">
          <div className="settings-widget dash-profile" > 
            <div className="settings-menu p-0">
              <div className="profile-bg">
                {/* <h5>Beginner</h5> */}
                
                <div className="go-dashboard text-center pb-2">
                  <Link to="/" className="btn btn-primary ">
                    Award Nomination
                  </Link>
                </div>
                <div className="text-center" >
                  <Link to="/" className="btn btn-primary">
                    25+ Million Readerbase
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="settings-widget account-settings feather-icon">
            <div className="settings-menu">
              <h3>DASHBOARD</h3>
              <div className="pb-3">
                <img src={User8} width={200} />
              </div>
              <div>
                <img src={User7} width={200} />
              </div>
              <div className="instructor-title">
                <h3>Top Journal </h3>
              </div>
              <ul>
                <li className="nav-item">
                     Journal -1 
                </li>
                <li className="nav-item">
                 
                     Journal -2
                </li>
                <li className="nav-item">
                  
                     Journal -3
                </li>
                <li className="nav-item">
                  
                     Journal -4
                </li>
              </ul>
            </div>
          </div>
          <div className="settings-widget account-settings feather-icon">
            <div className="settings-menu">
              <h3>ADS HERE</h3>
              <div className="pb-3">
                <img src={User8} width={200} />
              </div>
              <div>
                <img src={User7} width={200} />
              </div>
              <div className="instructor-title">
                <h3>Recommended Journals </h3>
              </div>
              <ul>
                <li className="nav-item">
                     Journal -1 
                </li>
                <li className="nav-item">
                 
                     Journal -2
                </li>
                <li className="nav-item">
                  
                     Journal -3
                </li>
                <li className="nav-item">
                  
                     Journal -4
                </li>
              </ul>
            </div>
          </div>
        </div>
      </StickyBox>
    </div>
  );
}
