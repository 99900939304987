import React from "react";
import { Col, Row } from "react-bootstrap";
// import { logo } from "../imagepath";
import { Link } from "react-router-dom";
import InstructorSidebar from "../instructor/sidebar/index";
import '../Journal/journal.css'
import Footer from "../footer";
import CourseHeader from "../pages/course/header";
import Bannerpage from "../Banner";
// import html from '../../assets/img/html.svg'
// import pdf from "../../assets/img/PDF_32.png"
// import {
//     NetIcon01,
//     NetIcon02,
//     NetIcon03,
//     NetIcon04
// } from '../imagepath'

const MainVolume = () => {
    return (
        <>
              <div className="main-wrapper">
            <CourseHeader activeMenu={"CourseDetails"} />
            <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        The Complete Web Developer Course 2.0
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Bannerpage />
            <div className="container pb-5">
                <Row>
                    <InstructorSidebar activeMenu="Courses" />
                    <Col>
                        {/* <div className="row p-3">
                            <div className="col-lg-12 d-flex">
                                <div className="row">
                                    <div className="col-6" style={{ textAlign: "left" }}>
                                        <p>Perspective - 35248/2385-5495.24.10.100</p>
                                    </div>
                                    <div className="col-6" style={{ textAlign: "right" }}>
                                        <p className="btn btn-primary me-2" ><i className="fa-regular fa-file-pdf" />&nbsp;View PDF</p>
                                        <p className="btn btn-primary" ><i className="fa-solid fa-download" />&nbsp;Download PDF</p>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <h5 style={{ textAlign: "left" }}><strong >Data Sharing and privacy in precision Medicine Research</strong></h5>
                                <span>
                                    Marvin Slepian*
                                </span>
                            </div>
                            <div>
                                <p><strong>*Correspondence:</strong>Marvin Slepian, Department of Medical
                                    Humanities and Ethics, Columbia Medical University, New York, USA, <strong>Email:</strong> </p>
                                <h3 className="hee">Description</h3>
                                <p>Bone marrow, the soft tissue found in the hollow centers of bones, plays an important role in the
                                    production of blood cells and the maintenance of the bodys immune system. Advances in bone
                                    marrow research have led to significant breakthroughs in understanding its function, the
                                    mechanisms underlying various diseases, and the development of new therapeutic strategies.
                                    This article explores some of the most exciting and advanced topics in bone marrow research
                                    today.</p>
                                <h5 className="hee">Bone marrow in aging and disease</h5>
                                <p>As the body ages, the bone marrow undergoes significant changes that can affect its function.
                                    Understanding these changes is important for developing strategies to moderate agerelated degeneration
                                    in hematopoiesis and immune function.</p>
                                <h3 className="hee">Conclusion</h3>
                                <p>
                                    Bone marrow research is at the forefront of biomedical science, offering new insights into
                                    the mechanisms of blood cell production, disease, and aging. Advances in transplantation
                                    techniques, understanding of the bone marrow function, and the development of novel therapies
                                    are transforming the treatment site for a wide range of conditions. As research continues
                                    to evolve, the potential for curing genetic disorders, regenerating damaged tissues, and
                                    improving outcomes for patients with hematologic diseases grows ever more. The integration
                                    of cutting-edge technologies and collaborative research efforts will undoubtedly lead to
                                    further breakthroughs, bringing hope to millions affected by bone marrow-related conditions.
                                </p>
                                <h3 className="hee">References</h3>
                                <ol className="refrences">
                                    <li>Okuyama C, Higashi T, Ishizu K, Saga T. FDG–PET findings associated with various medical procedures and treatments. Jpn J Radiol. 2023;41(5):459-476.
                                        [Crossref] [Google Scholar] [PubMed]</li>
                                    <li>
                                        Hayashida K, Fujita J, Miyake Y, Kawada H, Ando K, Ogawa S, et al. Bone marrow-derived cells contribute to pulmonary vascular remodeling in hypoxia-induced pulmonary hypertension. Chest. 2005;127(5):1793-1798.
                                        [Crossref] [Google Scholar] [PubMed]
                                    </li>
                                    <li>
                                        Vogado LH, Veras RM, Araujo FH, Silva RR, Aires KR. Leukemia diagnosis in blood slides using transfer learning in CNNs and SVM for classification. Eng Appl Artif Intell 2018;72:415-422.
                                        [Crossref] [Google Scholar]
                                    </li>
                                </ol>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="hee">Author Info</h5>
                                        <p>Author Name*</p>
                                        <p>Department of Orthopedics, University of Notre Dame, Notre Dame, USA
                                        </p>
                                        <p><strong>Citation:</strong>Chris B (2024) Advances in Bone Marrow Research: A New Therapeutic Potential.
                                            J Bone Res. 12:271.</p>
                                        <p><strong>Received:</strong> 03-Jun-2024, Manuscript No. BMRJ-24-32340;
                                            <strong> Editor assigned:</strong> 05-Jun-2024, Pre QC No. BMRJ-24-32340 (PQ);
                                            <strong> Reviewed:</strong> 19-Jun-2024, QC No. BMRJ-24-32340;
                                            <strong> Revised: </strong>26-Jun-2024, Manuscript No. BMRJ-24-32340 (R);
                                            <strong> Accepted:</strong> 03-Jul-2024 , DOI: 10.35841/2572-4916.24.12.271</p>
                                        <p> <strong>Copyright:</strong> 2024 Chris B. This is an open-access article distributed under the terms of the Creative
                                            Commons Attribution License, which permits unrestricted use, distribution, and reproduction in any
                                            medium, provided the original author and source are credited</p>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                        <div className="article-container">
                            <header className="article-header">
                                <h1>Advances in Bone Marrow Research: A New Therapeutic Potential</h1>
                                <p className="author-info">
                                    Chris Beagan<sup>*</sup>
                                    <br />
                                    Department of Orthopedics, University of Notre Dame, Notre Dame, USA.
                                </p>
                            </header>

                            <section className="article-content">
                                <h2 className="hee">Description</h2>
                                <p>
                                    Bone marrow, the soft tissue found in the hollow centers of bones, plays an
                                    important role in the production of blood cells and the maintenance of the
                                    body’s immune system. Advances in bone marrow research have led to
                                    significant breakthroughs in understanding its function...
                                </p>

                                <h2 className="hee">Bone marrow transplantation</h2>
                                <p>
                                    Bone Marrow Transplantation (BMT) has long been a critical treatment for various hematologic conditions, including leukemia, lymphoma, and aplastic anemia...
                                </p>

                                <h3 className="hee">Haploidentical transplantation</h3>
                                <p>
                                    One of the major advancements is the increased use of haploidentical (half-matched) transplants. These transplants expand the donor pool, making it easier for patients to find suitable matches...
                                </p>

                                {/* Add more sections as necessary */}
                            </section>

                            <section className="conclusion">
                                <h2 className="hee">Conclusion</h2>
                                <p>
                                    Bone marrow research is at the forefront of biomedical science, offering new insights into the mechanisms of blood cell production, disease, and aging...
                                </p>
                            </section>

                            <section className="references">
                                <h2 className="hee">References</h2>
                                <ul>
                                    <li>
                                        Okyama A, Higashi T, Ishizu K, Saga T. FDG-PET findings associated with various medical procedures and treatments...
                                    </li>
                                    <li>
                                        Hayashida K, Fujita J, Miyake Y, Kawada H, Ando K, Ogawa S, et al. Bone marrow derived cells contribute to pulmonary vascular remodeling in hypoxia-induced pulmonary hypertension...
                                    </li>
                                    {/* Add more references as necessary */}
                                </ul>
                            </section>
                            <section className="author-info-section">
                                <h2 className="hee">Author Info</h2>
                                <p><strong>Chris Beagan</strong></p>
                                <p>Department of Orthopedics, University of Notre Dame, Notre Dame, USA</p>
                                <p>
                                    <strong>Citation:</strong> Chris B (2024) Advances in Bone Marrow Research: A New Therapeutic Potential. J Bone Res. 12:271.
                                </p>
                                <p>
                                    <strong>Received:</strong> 03-Jun-2024, Manuscript No. BMRJ-24-32340; <strong>Editor assigned:</strong> 05-Jun-2024, Pre QC No. BMRJ-24-32340 (PQ); <strong>Reviewed:</strong> 19-Jun-2024, QC No. BMRJ-24-32340; <strong>Revised:</strong> 26-Jun-2024, Manuscript No. BMRJ-24-32340 (R); <strong>Accepted:</strong> 03-Jul-2024, DOI: 10.35841/2572-4916.24.12.271
                                </p>
                                <p>
                                    <strong>Copyright:</strong> 2024 Chris B. This is an open-access article distributed under the terms of the Creative Commons Attribution License...
                                </p>
                            </section>
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer />
            </div>
        </>
    )
}

export default MainVolume