// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';

// import theme reducers
// import settingsReducer from 'settings/settingsSlice';
import journalReducer from './Redux/journal/journalslice';
import homeReducer from './Redux/home/homeslice';
import instructionsReducer from './Redux/AuthorInstructions/AuthorInstructionSlice';
import manuscriptReducer from './Redux/submitmanuscript/submitmanuscriptSlice';
import spissuesReducer from './Redux/SpecialIssues/specialissueSlice';
import editorialReducer from './Redux/Editorial/editorialSlice';
import archiveReducer from './Redux/Archive/archiveSlice';
import contactinfoReducer from './Redux/Contact/constactslice';
import articleReducer from './Redux/Articles/articleSlice';
import aimScopeReducer from './Redux/AimScope/aimScopeSlice';
// import persist key
// import { REDUX_PERSIST_KEY } from 'config.js';

const persistConfig = {
  key: 'root', // REDUX_PERSIST_KEY
  storage,
  // whitelist: ['Home'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    jounalListData : journalReducer,
    Home : homeReducer,
    instuctionData : instructionsReducer,
    manuscriptData : manuscriptReducer,
    spIssueData : spissuesReducer,
    editorialData : editorialReducer,
    archiveData : archiveReducer,
    contactData : contactinfoReducer,
    articleData : articleReducer,
    aimScopeData : aimScopeReducer,
    // ConcumerSubscriptions: ConsumerSubscriptionReducer,
  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
