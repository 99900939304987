// import React, { useEffect } from 'react'
// import Footer from '../footer'
// import Header from '../header'
// import { useDispatch, useSelector } from 'react-redux';
// import { settingsData } from '../../Redux/home/homeslice';

// const ContactUs = () => {

//     const dispatch = useDispatch();
//     useEffect(() => {
//       dispatch(settingsData())
//     },[])

//     const { settings } = useSelector((state) => state.Home);

//     return (
//         <>
//             <Header />
//             <div className="breadcrumb-bar">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12 col-12">
//                             <div className="breadcrumb-list">
//                                 <nav aria-label="breadcrumb" className="page-breadcrumb">
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="page-banner">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12 col-12">
//                             <h1 className="mb-0">Contact Us</h1>
//                         </div>
//                     </div>
//                 </div>
//             </div>      
//             <div className='topspace'>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-4'>
//                             <h4>ADDRESS</h4>
//                             <div dangerouslySetInnerHTML={{__html : settings?.data?.address}}/>
//                             {/* <p>1-1, Unknown Area, Not found City <br />
//                                 Somewhere in earth</p> */}
//                         </div>
//                         <div className='col-4'>
//                             <h4>Contact Number</h4>
//                             <p>{settings?.data?.phone}</p>
//                         </div>
//                         <div className='col-4'>
//                             <h4>Contact Email</h4>
//                             <p>{settings?.data?.email}</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </>
//     )
// }

// export default ContactUs

import React, { useEffect, useState } from "react";
// import CourseHeader from "../header";
import '../Journal/journal.css'
import Footer from "../footer";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { settingsData } from "../../Redux/home/homeslice";
import Header from "../header";

const ContactUs = () => {

    const { contactinfo } = useSelector((state) => state.contactData)

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        journalclassification_uuid: contactinfo?.data?.classification_uuid,
        message: ""
    });

    const handleSubmitAPI = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_URL}/contactQuery`, formData)
            .then((res) => {
                console.log(res?.data)
                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    mobile: "",
                    journalclassification_uuid: "",
                    message: ""
                })
            })
            .catch((err) => {
                console.log(err?.response?.data)
            })
        console.log(formData);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const dispatch = useDispatch();
    // const { slug } = useParams();

    useEffect(() => {
        dispatch(settingsData());
    }, [])

    const { settings } = useSelector((state) => state.Home);

    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">Contact Us</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mx-auto">
                            <div className="support-wrap">
                                <div className="row">
                                    <div className="col-md-5 col-lg-5 col-xl-5 col-sm-6">
                                        <strong>Address</strong>
                                        <br />
                                        <label dangerouslySetInnerHTML={{ __html: settings?.data?.address }}>
                                        </label>
                                        <br />
                                        <strong>Registered Address</strong>
                                        <br />
                                        <label
                                            dangerouslySetInnerHTML={{ __html: settings?.data?.registered_address }}
                                        >
                                        </label>
                                        <br />
                                        <strong>Phone Number</strong>
                                        <br />
                                        <label>
                                            {settings?.data?.phone}
                                        </label>
                                        <br />
                                        <strong>Email</strong>
                                        <br />
                                        <label>
                                            {settings?.data?.email}
                                        </label>
                                        <br />
                                        <strong>Reprints / Advertisement</strong>
                                        <br />
                                        <label>
                                            {settings?.data?.reprint_advertisement}
                                        </label>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6">
                                        <form onSubmit={handleSubmitAPI} >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input
                                                            name="first_name"
                                                            value={formData.first_name}
                                                            onChange={handleChange}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your first name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Last Name</label>
                                                        <input
                                                            name="last_name"
                                                            value={formData.last_name}
                                                            onChange={handleChange}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your last name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Enter your email address"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Phone</label>
                                                        <input
                                                            name="mobile"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            type="tel"
                                                            className="form-control"
                                                            placeholder="Enter your phone number"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Message</label>
                                                        <textarea
                                                            name="message"
                                                            value={formData.message}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Write down here"
                                                            rows={4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <button className="btn-submit">Submit</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs