import React, { useEffect } from "react";
import Header from "../../header";
import { Col, Row } from "react-bootstrap";
import doc from '../../../assets/img/user/doc.jpg'
import { Link, useParams } from "react-router-dom";
import Footer from "../../footer";
import CourseHeader from "../../header/index";
import StudentSideBar from "../../student/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { EditorialsgetById } from "../../../Redux/Editorial/editorialSlice";
import Bannerpage from "../../Banner";

export const InstructorList = () => {

  const { editorial } = useSelector((state) => state.editorialData)

const { slug } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(EditorialsgetById(slug));
  }, [])

  return (
    <>
      <Header />
      <CourseHeader activeMenu={"CourseDetails"} />

      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      All Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      The Complete Web Developer Course 2.0
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bannerpage />

      <div className="container p-2">
        <Row>
          <StudentSideBar />
          <Col>
            {editorial?.data?.[0]?.editorial_boards?.map((item, index) => {
              return <div className="row" key={index}>
                <div className="col-lg-12 d-flex">
                  <div className="instructor-list flex-fill">
                    <div className="instructor-img">
                      <Link>
                        <img className="img-fluid" alt="" src={item?.image_url || doc} crossOrigin="anonymous" />
                      </Link>
                    </div>
                    <div className="instructor-content">
                      <h5>
                        <Link to={`/${slug}/instructor-profile/${window.btoa(item?.uuid)}/board`}>{item?.name}</Link>
                      </h5>
                      <h6>{item?.board_type}</h6>
                      <div className="instructor-badge">
                        <Link to={`/${slug}/instructor-profile/${window.btoa(item?.uuid)}/boardb`}><span className="web-badge">Biography</span>&nbsp;</Link>
                        <Link to={`/${slug}/instructor-profile/${window.btoa(item?.uuid)}/boardr`}><span className="web-badge">Research Interest</span>&nbsp;</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};
