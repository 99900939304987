// details.js
export const details = [
    {
        id: 1,
        "Title": "Medical Sciences",
        "Items": [
            "Journal of Tumor Research",
            "Medical & Surgical Urology",
            "Journal of Molecular Pathology and Biochemistry"
        ]
    },
    {
        id: 2,
        "Title": "Bio Chemistry",
        "Items": [
            "Mass Spectrometry & Purification Techniques",
            "Journal of Plant Biochemistry & Physiology",
            "Journal of Chemical Engineering & Process Technology"
        ]
    },
    {
        id: 3,
        "Title": "Pharmaceutical Sciences",
        "Items": [
            "Journal of Pharmaceutical Care & Health Systems",
            "Journal of Developing Drugs",
            "Journal of Alcoholism & Drug Dependence"
        ]
    },
    {
        id: 4,
        "Title": "Clinical Sciences",
        "Items": [
            "Journal of Phonetics & Audiology",
            "Journal of Clinical Toxicology",
            "Journal of Infectious Diseases & Preventive Medicine"
        ]
    },
    {
        id: 5,
        "Title": "Genetics & Molecular Biology",
        "Items": [
            "Journal of Genetic Syndromes & Gene Therapy",
            "Journal of Cell Science & Therapy",
            "Journal of Fertilization: In Vitro - IVF-Worldwide, Reproductive Medicine, Genetics & Stem Cell Biol"
        ]
    },
    {
        id: 6,
        "Title": "Immunology & Microbiology",
        "Items": [
            "Journal of Clinical Microbiology and Antimicrobials",
            "HIV: Current Research",
            "Immunological Disorders and Immunotherapy"
        ]
    },
    {
        id: 7,
        "Title": "Pharmaceutical Sciences",
        "Items": [
            "Journal of Pharmaceutical Care & Health Systems",
            "Journal of Developing Drugs",
            "Journal of Alcoholism & Drug Dependence"
        ]
    },
    {
        id: 8,
        "Title": "Clinical Sciences",
        "Items": [
            "Journal of Phonetics & Audiology",
            "Journal of Clinical Toxicology",
            "Journal of Infectious Diseases & Preventive Medicine"
        ]
    },
];

export const communications = [
    {
        head: "Short Communication",
        name: "Brief Note on Principles of Affinity Chromatography"
    },
    {
        head: "Mini Review",
        name: "A Unique Approach towards Modern Clinical Trials of Drugs"
    },
    {
        head: "Mini Review",
        name: "The Role of Bile Acids in the Liver and Biliary Diseases"
    },
    {
        head: "Research Article",
        name: "Brief Note on Principles of Affinity Chromatography"
    },
    {
        head: "Short Communication",
        name: "A Unique Approach towards Modern Clinical Trials of Drugs"
    },
    {
        head: "Short Communication",
        name: "The Role of Bile Acids in the Liver and Biliary Diseases"
    },
    {
        head: "Mini Review",
        name: "Brief Note on Principles of Affinity Chromatography"
    },
    {
        head: "Mini Review",
        name: "The Role of Bile Acids in the Liver and Biliary Diseases"
    }
];