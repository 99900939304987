import React from "react";
import Header from "../header";
import Header5 from "../header 5";
import { Col, Row } from "react-bootstrap";
import { logo } from "../imagepath";
import { Link } from "react-router-dom";
import InstructorSidebar from "../instructor/sidebar/index";
import '../Journal/journal.css'
import Footer from "../footer";


const athorPage = () => {
    return (
        <>
            <Header />
            <div className="top-head ">
                <div className="container ">
                    <Row>
                        <Col>
                            <Link to="/" className="menu-logo">
                                <img src={logo} className="img-fluid" alt="Logo" />
                            </Link>
                        </Col>
                        <Col>
                            <h2 className="mb-0" style={{ color: "#fff" }}>
                                Journal-1
                            </h2>
                            <p style={{ color: "#fff", textAlign: "end" }}>Open Access</p>
                        </Col>
                        <Col>
                            <h4 className=" text-center pb-2" style={{ color: "#fff" }}>ISSN: 2165-7548</h4>
                            <div className="go-dashboard text-center pb-2">
                                <p className="btn btn-primary ">
                                    <i className="fa-brands fa-whatsapp" /> &nbsp;
                                    +44 1223 790975
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Header5 />
            <div className="container pb-5">
                <Row>
                    <InstructorSidebar activeMenu="Courses" />
                    <Col>
                        <div className="row p-3">
                            {/* Instructor List */}
                            <div>
                                <h4>Author Name</h4>
                                <p>Department of Orthopedics, University of Notre Dame, Notre Dame, USA</p>
                                <strong>Publications</strong>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="hee">Short Communication</h5>
                                        <p>Advances in Bone Marrow Research: A New Therapeutic Potential</p>
                                        <p><strong>Author(s):</strong> Chris Beagan*</p>
                                        <p><strong>DOI:</strong> 10.35841/2572-4916.24.12.271</p>
                                        <div className="row">
                                            <span className="col-3">
                                                <Link to="/" className="butto">
                                                    HTML
                                                </Link></span>
                                            <span className="col-3">
                                                <Link to="/" className="butto">
                                                    PDF
                                                </Link>
                                            </span>
                                        </div>
                                    </div>x
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}

export default athorPage