import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import OwlCarousel from "react-owl-carousel";
import {
  // Course1,
  // Course5,
  // Course7,
  // Course8,
  // Course9,
  // Icon1,
  // Icon2,
  // User,
  // User1,
  // User10,
  // User2,
  // User3,
  // User4,
  // User5,
  // User7,
  // User8,
  // User9,
} from "../../imagepath";
// import BgBanner from "../../../assets/img/bg-banner.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import { communications } from "./topics";

const TrendingCourse = () => {

  const { highLightIssues } = useSelector((state) => state.Home)

  // var settings = {
  //   //autoWidth: true,
  //   items: 2,
  //   margin: 25,
  //   dots: true,
  //   nav: true,
  //   navText: [
  //     '<i className="fas fa-arrow-left"></i>',
  //     '<i className="fas fa-arrow-right"></i>',
  //   ],

  //   loop: true,
  //   responsiveClass: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     768: {
  //       items: 3,
  //     },
  //     1170: {
  //       items: 3,
  //     },
  //   },
  // };

  // var course = {
  //   //autoWidth: true,
  //   items: 2,
  //   margin: 25,
  //   dots: true,
  //   nav: true,
  //   navText: [
  //     '<i className="fas fa-arrow-left"></i>',
  //     '<i className="fas fa-arrow-right"></i>',
  //   ],

  //   loop: true,
  //   responsiveClass: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     768: {
  //       items: 3,
  //     },
  //     1170: {
  //       items: 4,
  //     },
  //   },
  // };

  return (
    <section
      className="section trend-course"
    >
      <div className="container">

        <div className="section-header aos" data-aos="fade-up">
          <div className="section-sub-head">
            <h2>Current Issue Highlights
            </h2>
          </div>

        </div>
        <div className="section-text aos" data-aos="fade-up">
          <p className="mb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean
            accumsan bibendum gravida maecenas augue elementum et neque.
            Suspendisse imperdiet.
          </p>
        </div>
        {/* <OwlCarousel
          {...settings}
          className="owl-carousel trending-course owl-theme aos"
          data-aos="fade-up"
        > */}
        <div className="row">
          {highLightIssues?.data?.map((detail, index) => (
            <div className="col-lg-3 col-md-6 d-flex mb-3" key={index}>
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-content">
                    <div>
                      <h6>{detail.article_title}</h6>
                    </div>
                    <div className="title mb-2 truncate-2-lines" style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{__html : detail.abstract}}/>
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">

                        <img src={detail?.image_url} alt="" className="img-fluid" />

                        <div className="course-name">
                          <h6>
                            {detail?.author_name}
                          </h6>
                          {/* <p>Author</p> */}
                        </div>
                      </div>
                    </div>

                    <div className="course-info d-flex align-items-center">
                    </div>
                    <div className="row">
                      <span className="col-3">
                        <Link to="/" className="butto">
                          Abstract
                        </Link></span>&nbsp;&nbsp;
                      <span className="col-3">
                        <Link to="/" className="butto">
                          HTML
                        </Link></span>
                      <span className="col-3">
                        <Link to="/" className="butto">
                          PDF
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TrendingCourse;
