import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import InnerBanner from "../../assets/img/inner-banner.jpg";
import Footer from "../footer";
import StudentSideBar from "../student/sidebar";
import Header from "../header";
import CourseHeader from "../header/index";

import { useDispatch, useSelector } from "react-redux";
import { ManuscriptsgetById } from "../../Redux/submitmanuscript/submitmanuscriptSlice";
import Bannerpage from "../Banner";

export default function SubmitManuscript() {

  const { manuscript } = useSelector((state) => state.manuscriptData)

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(ManuscriptsgetById(slug));
  }, [])

  return (
    <>
      <>
        <Header />
        <CourseHeader activeMenu={"CourseDetails"}/>
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        The Complete Web Developer Course 2.0
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Bannerpage />
        
        <div className="container p-5">
          <Row>
            <StudentSideBar />
            <Col>
              <h1>Submit Manuscript</h1>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="card overview-sec">
                    <div className="card-body">
                      <div dangerouslySetInnerHTML={{ __html: manuscript?.data?.manuscript }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    </>
  
  );
}
